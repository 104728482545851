import React from 'react';
import { Route } from 'react-router-dom'
import Staking from './components/staking'


class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      is_unlocked: false
    }

  }


  componentDidMount() {
    // window.addEventListener('load', this.handleConnection)
  }

  handleConnection = async () => {
    try {
      let is_wallet_connected = await window.connectWallet()
      this.setState({is_wallet_connected, coinbase: await window.web3.eth.getCoinbase()})
    } catch (e) {
      window.alertify.error(String(e))
    }
  }


 render() {
  return (
    
        <Route exact to="/" render={props => <Staking {...props} />} />
      
  );
}
}

export default App;
