import React from 'react'
import { Table } from 'react-bootstrap'
import Web3 from 'web3'
import Contract_ABI from '../api/api.json'
import Staking_Contract_ABI from '../api/staking_api.json'


export default class Staking extends React.Component {
    constructor() {
      super()
      this.state = {
        token_symbol: 'SWANMXI',
        wallet_address: '',
        allowance: 0,
        approve: false,
        stake: false,
        max_value: '',
        un_max_value: '',
        deposited_tokens: 0,
        balance_of: 0,
      }
    }

    render() {
      let web3 = new Web3();
      if (typeof window.web3 === 'undefined') {
        window.alertify.error('Error: No web3 detected!');
      }
      else {
        web3 = new Web3(window.web3.currentProvider);
      }


      const handleBalanceOf = async () => {
        try {

          const OBJ = new web3.eth.Contract(JSON.parse(Contract_ABI.result), process.env.REACT_APP_CONTRACT);
          // eslint-disable-next-line no-unused-vars
          let balanceOfTemp = await OBJ.methods.balanceOf(this.state.wallet_address).call();
          this.setState({balance_of: balanceOfTemp});
        } catch (error) {
          // window.alertify.error(error.message);
        }
      }

      const handleDepositedTokens = async () => {
        try {

          const OBJ = new web3.eth.Contract(JSON.parse(Staking_Contract_ABI.result), process.env.REACT_APP_STAKING_CONTRACT);
          // eslint-disable-next-line no-unused-vars
          let depositedTokensTemp = await OBJ.methods.depositedTokens(this.state.wallet_address).call();
          depositedTokensTemp = depositedTokensTemp / 1000000000000000000;
          this.setState({deposited_tokens: depositedTokensTemp});
        } catch (error) {
          // window.alertify.error(error.message);
        }
      }
      
      const handleUnstake = async () => {
        if(this.state.wallet_address === '') {
          handleConnect();
        }
        else {
          if (this.state.un_max_value === '') {
            window.alertify.error('Unstake is required.');
            return false;
          } 
          else if (!parseFloat(this.state.un_max_value)) {
            this.setState({un_max_value: ''});
            window.alertify.error('Please, Enter valid value.');
            return false;
          }
          else if (parseFloat(this.state.un_max_value) < 10) {
            window.alertify.error('Minimum 10 unstake is required.');
            return false;
          }


          document.getElementById("unstake_btn").disabled = true;
          document.getElementById("unstake_btn").textContent = "Processing...";
          
          try {

            const OBJ = new web3.eth.Contract(JSON.parse(Staking_Contract_ABI.result), process.env.REACT_APP_STAKING_CONTRACT);
            let depositedTokensTemp = await OBJ.methods.depositedTokens(this.state.wallet_address).call();
            depositedTokensTemp = depositedTokensTemp-1000000000000000000;
            depositedTokensTemp = depositedTokensTemp/1000000000000000000;
            if (parseFloat(this.state.un_max_value) >= depositedTokensTemp) {
              window.alertify.error('Insufficient Stake!');
              document.getElementById("unstake_btn").disabled = false;
              document.getElementById("unstake_btn").textContent = "UNSTAKE";

              return false;
            }
          } catch (error) {
            window.alertify.error(error.message);
            document.getElementById("unstake_btn").disabled = false;
            document.getElementById("unstake_btn").textContent = "UNSTAKE";

            return false;
          }

          try {

            const OBJ = new web3.eth.Contract(JSON.parse(Staking_Contract_ABI.result), process.env.REACT_APP_STAKING_CONTRACT);
            let tempNo = parseFloat(this.state.un_max_value) * 1000000000000000000;
            await OBJ.methods.unstake(tempNo.toString()).send({
              'from': this.state.wallet_address
            }).on('receipt', (receipt) => {
              if (receipt['transactionHash'] !== "") {
                window.alertify.success('Your unstaking request is submitted successfully.');
                this.setState({un_max_value: ''});
                handleDepositedTokens();
              } else {
                window.alertify.error('Something Went Wrong, Please Try Again!');
              }

              document.getElementById("unstake_btn").disabled = false;
              document.getElementById("unstake_btn").textContent = "UNSTAKE";
            });

          } catch (error) {
            window.alertify.error(error.message);
            document.getElementById("unstake_btn").disabled = false;
            document.getElementById("unstake_btn").textContent = "UNSTAKE";
          }
        }
      }

      const handleClaim = async () => {
        if(this.state.wallet_address === '') {
          handleConnect();
        }
        else {
          document.getElementById("claim_btn").disabled = true;
          document.getElementById("claim_btn").textContent = "Processing...";

          try {

            const OBJ = new web3.eth.Contract(JSON.parse(Staking_Contract_ABI.result), process.env.REACT_APP_STAKING_CONTRACT);
            await OBJ.methods.claim().send({
              'from': this.state.wallet_address
            }).on('receipt', (receipt) => {
              if (receipt['transactionHash'] !== "") {
                window.alertify.success('Your claim request is submitted successfully.');
              } else {
                window.alertify.error('Something Went Wrong, Please Try Again!');
              }

              document.getElementById("claim_btn").disabled = false;
              document.getElementById("claim_btn").textContent = "CLAIM";
            });

          } catch (error) {
            window.alertify.error(error.message);
            document.getElementById("claim_btn").disabled = false;
            document.getElementById("claim_btn").textContent = "CLAIM";
          }
        }    
      }

      const handleStake = async () => {
        if(this.state.wallet_address === '') {
          handleConnect();
        }
        else {
          if(this.state.max_value === '') {
            window.alertify.error('Stake is required.');
            return false;
          }
          // eslint-disable-next-line use-isnan
          else if (!parseFloat(this.state.max_value)) {
            this.setState({max_value: ''});
            window.alertify.error('Please, Enter valid value.');
            return false;
          }
          else if (parseFloat(this.state.max_value) < 10) {
            window.alertify.error('Minimum 10 stake is required.');
            return false;
          }


          document.getElementById("stake_btn").disabled = true;
          document.getElementById("stake_btn").textContent = "Processing...";

          try {

            const OBJ = new web3.eth.Contract(JSON.parse(Staking_Contract_ABI.result), process.env.REACT_APP_STAKING_CONTRACT);
            let tempNo = parseFloat(this.state.max_value) * 1000000000000000000;
            await OBJ.methods.stake(tempNo.toString()).send({
              'from': this.state.wallet_address
            }).on('receipt', (receipt)=> {
              if(receipt['transactionHash'] !== "") {
                window.alertify.success('Your staking request is submitted successfully.');
                this.setState({max_value: ''});
                handleDepositedTokens();
              }
              else {
                window.alertify.error('Something Went Wrong, Please Try Again!');
              }

              document.getElementById("stake_btn").disabled = false;
              document.getElementById("stake_btn").textContent = "2.STAKE";
            });
          
          } catch (error) {
            window.alertify.error(error.message);
            document.getElementById("stake_btn").disabled = false;
            document.getElementById("stake_btn").textContent = "2.STAKE";
          }
        }
      }

      const handleApprove = async () => {
        if(this.state.wallet_address === '') {
          handleConnect();
        }
        else {
          document.getElementById("approve_btn").disabled = true;
          document.getElementById("approve_btn").textContent = "Processing...";

          try {

            const OBJ = new web3.eth.Contract(JSON.parse(Contract_ABI.result), process.env.REACT_APP_CONTRACT);
            await OBJ.methods.approve(process.env.REACT_APP_STAKING_CONTRACT, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({
              'from': this.state.wallet_address
            }).on('receipt', (receipt) => {
              if (receipt['transactionHash'] !== "") {
                window.alertify.success('Your transaction is successfully approved.');
                this.setState({
                  approve: true
                });
                this.setState({
                  stake: false
                });
              } else {
                window.alertify.error('Something Went Wrong, Please Try Again!');
              }

              document.getElementById("approve_btn").disabled = false;
              document.getElementById("approve_btn").textContent = "1.APPROVE";
            });

          } catch (error) {
            window.alertify.error(error.message);
            document.getElementById("approve_btn").disabled = false;
            document.getElementById("approve_btn").textContent = "1.APPROVE";
          }
        }
      }

      const handleAllowance = async () => {
        const OBJ = new web3.eth.Contract(JSON.parse(Contract_ABI.result), process.env.REACT_APP_CONTRACT);
        let allowanceTemp = await OBJ.methods.allowance(this.state.wallet_address, process.env.REACT_APP_STAKING_CONTRACT).call();
        if (allowanceTemp > 0) {
          this.setState({approve: true});
          this.setState({stake: false});
        }
        else {
          this.setState({approve: false});
          this.setState({stake: true});
          this.setState({allowance: allowanceTemp});
        }
      }

      const handleConnect = async () => {
        if (typeof window.web3 !== 'undefined') {
          try {
            let networkid = await web3.eth.net.getId();
            if(networkid !== 4) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(4) }],
              });
            }
          }
          catch (error) {
            window.alertify.error(error.message);
          }
          

          try {
            let accounts = await web3.eth.getAccounts();

            if (accounts.length === 0) {
              if (typeof window.ethereum !== 'undefined') {
                if (typeof window.ethereum.isMetaMask !== 'undefined') {
                  await window.ethereum.request({
                    method: 'eth_requestAccounts'
                  });
                  window.location.reload();
                  accounts = await web3.eth.getAccounts();
                  this.setState({wallet_address: accounts[0]});
                  handleAllowance();
                  handleDepositedTokens();
                  handleBalanceOf();
                }
                else {
                  window.alertify.error('This MetaMask is Not supported!');
                }
              }
              else {
                window.alertify.error('MetaMask is not installed!');
              }
            }
            else {
              this.setState({wallet_address: accounts[0]});
              handleAllowance();
              handleDepositedTokens();
              handleBalanceOf();
            }
          }
          catch (error) {
            window.alertify.error(error.message);
          }
        }
      }

      const handleConnectCall = async () => {
        if (typeof window.web3 !== 'undefined') {
          try {
            let networkid = await web3.eth.net.getId();
            if (networkid !== 4) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                  chainId: web3.utils.toHex(4)
                }],
              });
            }
          } catch (error) {
            // window.alertify.error('Error: No web3 detected!');
          }


          try {
            let accounts = await web3.eth.getAccounts();

            if (accounts.length !== 0) {
              this.setState({
                wallet_address: accounts[0]
              });
              handleAllowance();
              handleDepositedTokens();
              handleBalanceOf();
            }
          } catch (error) {
            // window.alertify.error('Error: No web3 detected!');
          }
        }
      }

      const handleLoad = () => {
        handleConnectCall();
        setInterval(() => handleConnectCall(), 1000)
      }


      window.onload = function () {
        handleLoad();
      };


      return (
        <div className='App'>
          <div className='token-section'>
            <div className='container'>

              <div className = 'row mt-4'>

                <div className="col-lg-9 col-sm-6">
                </div>

                <div className="col-lg-3 col-sm-6 unlock-wallet">
                  <button style={{ borderRadius: '6px' }} onClick={handleConnect}><i className="fas fa-user"></i>&nbsp; &nbsp;{this.state.wallet_address === '' ? "Connect Wallet" : "Wallet Connected"}</button>
                </div>

              </div>

              <div className='row mt-4'>

                <div className='col-lg-6'>
                  <div className='bg-blue'>
                    <Table>
                      <tbody>
                        <tr>
                          <td className='b-t-0'>Total Value Staked</td>
                          <td className='b-t-0 bold'>{this.state.deposited_tokens} {this.state.token_symbol}</td> 
                        </tr>
                      </tbody>
                    </Table>
                    <div className='bdr-top-table'>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className='form-group'>
                            <label htmlFor='deposit-amount' className='d-block text-center'>Stake</label>
                            <div className='input-group input-group-lg'>
                                <input className='form-control left-radius max_value' id="max_value" placeholder='0' type='text' value={this.state.max_value} onChange={(event) => this.setState({ max_value: event.target.value })} />
                                <div className='input-group-append'>
                                    <button className='btn btn-primary' style={{background: '#4DB8FD'}} >
                                        <strong>Max</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{paddingRight: '0.3rem'}} className='center-button'>
                                {this.state.approve === true ?
                                <button className='btn btn-block btn-primary btn-lg' type='button' disabled>
                                    1.APPROVE
                                </button>
                                : 
                                <button className='btn btn-block btn-primary btn-lg' type='button' id='approve_btn' onClick={handleApprove}>
                                    1.APPROVE
                                </button>
                                }
                            </div>
                            <div style={{paddingLeft: '0.3rem'}} className='center-button'>
                                {this.state.stake === true ?
                                <button className='btn btn-block btn-primary btn-lg' type='button' disabled>
                                    2.STAKE
                                </button>
                                : 
                                <button className='btn btn-block btn-primary btn-lg' type='button' id='stake_btn' onClick={handleStake}>
                                    2.STAKE
                                </button>
                                }
                            </div>
                        </div>
                        <p style={{fontSize: '.8rem'}} className='mt-1 text-center'>
                            Please approve before staking. Step 1: approve , Step 2: stacking 
                        </p>
                      </form>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='bg-blue'>
                    <Table className=''>
                      <tbody>
                        <tr>
                          <td className='b-t-0'>Balance</td>
                          <td className='b-t-0 bold'>{this.state.balance_of} {this.state.token_symbol}</td>
                        </tr>
                        <tr>
                          <td>Staked</td>
                          <td className='bold'>{this.state.deposited_tokens} {this.state.token_symbol}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className='bdr-top-table'>
                      <div className='center-button'>
                        <form>
                          <button className='btn btn-primary btn-block btn-lg' type='button' id='claim_btn' onClick={handleClaim}>
                              CLAIM
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3 mt-5">
                </div>

                <div className='col-lg-6 mt-5'>
                  <div className='bg-blue'>
                    <Table>
                      <tbody>
                        <tr>
                          <td className='b-t-0'>Total Value Staked</td>
                          <td className='b-t-0 bold'>{this.state.deposited_tokens} {this.state.token_symbol}</td> 
                        </tr>
                      </tbody>
                    </Table>
                    <div className='bdr-top-table'>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className='form-group'>
                            <label htmlFor='deposit-amount' className='d-block text-center'>Unstake</label>
                            <div className='input-group input-group-lg'>
                                <input className='form-control left-radius un_max_value' id="un_max_value" placeholder='0' type='text' value={this.state.un_max_value} onChange={(event) => this.setState({ un_max_value: event.target.value })} />
                                <div className='input-group-append'>
                                    <button className='btn btn-primary' style={{background: '#4DB8FD'}} >
                                        <strong>Max</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{paddingLeft: '0.3rem'}} className='center-button'>
                                <button className='btn btn-block btn-primary btn-lg' type='button' id='unstake_btn' onClick={handleUnstake}>
                                    UNSTAKE
                                </button>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3 mt-5">
                </div>
                
              </div>
            </div>
          </div>
        </div>
      );
    }
    
}